import React from "react";
import * as types from "./../redux/actionType";
import "./../SCSS/_homePageCategoryBlock.scss";
import { Link } from "react-router-dom";
import Heading7 from "./Font/Heading7";
import slider_leftArrow from "./../assets/Icon/left_arrow.svg";
import slider_rightArrow from "./../assets/Icon/right_arrow.svg";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useWindowDimensions from "./helpers/utils/useWindowDimensions";
import { SmallImageResize } from "./helpers/utils/imageResize";
import LazyImage from "./MostSharedComponent/LazyImage";
import { defaultImageHelper } from "./helpers/utils/defaultImageHelper";

function HomePageCategoryBlock() {
  const categoryData = useSelector((state) => state.appData.categoryData);
  const { width } = useWindowDimensions();
  const [hideLeftIcon, setHideLeftIcon] = React.useState(true);
  const [hideLeftEnIcon, setHideLeftEnIcon] = React.useState(true);

  const currentLanguageAr = (localStorage.getItem("i18next") || "en") === "ar";
  const PrevArrow = ({ style, onClick = () => { }, currentSlide }) => {
    return (
      <button
        className="category__slider__btn left__arrow"
        style={{
          display: !currentLanguageAr && hideLeftEnIcon && "none",
        }}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
          setHideLeftIcon(false);
        }}
      >
        <img
          src={slider_leftArrow}
          className={"cat_arrow_img"}
          alt="Prev Arrow"
          style={{ ...style }}
        />
      </button>
    );
  };

  const NextArrow = ({ onClick = () => { } }) => {
    return (
      <button
        className="category__slider__btn right__arrow"
        style={{
          display: currentLanguageAr && hideLeftIcon && "none",
        }}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
          setHideLeftEnIcon(false);
        }}
      >
        <img
          src={slider_rightArrow}
          className="cat_arrow_img"
          alt="Next Arrow"
        />
      </button>
    );
  };

  const viewSlider = width > 767;

  const categorysData = [...categoryData?.children_data]?.filter(
    (child_data) => child_data?.id !== 898
  );
  
  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: viewSlider ? 4 : categorysData?.length / 2,
    slidesToScroll: 5,
    initialSlide: currentLanguageAr ? 5 : 0,
    variableWidth: true,
    arrows: viewSlider,
    swipeToSlide: false,
    speed: 200,
    rtl: currentLanguageAr,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    onInit: (props) => {
      setHideLeftIcon(true);
      setHideLeftEnIcon(true);
    },
  };
  return (
    <div className="home__page__category__block">
      <div className="row home__page__category__inner__block">
        <Slider {...settings}>
          {categorysData?.map((cat, catIndex) => {
            let catObjUrl = `category/${cat.url_key}-mc-${cat.id}`;
            let imgUrl = null;
            if (cat.categorybanner !== "") {
              // NOTE: This is a hack to fix the double pub/media in the image URL
              imgUrl = `${process.env.REACT_APP_PROJECT_MEDIA_API_URL}${cat.categorybanner}`.replace('/pub/media//pub/media/', '/pub/media/');
            }
            return (
              <div
                key={cat.id}
                className="col-4 col-md-2 category__block"
              // style={{ width: "300px" }}
              >
                <Link to={catObjUrl}>
                  <LazyImage
                    imgSrc={imgUrl}
                    srcSetReSize={SmallImageResize}
                    alt={`category ${cat.name}`}
                    className="category__image"
                  />
                  <Heading7 text={cat.name}></Heading7>
                </Link>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default HomePageCategoryBlock;
